import {Redirect, Route} from "found";
import React from "react";

import Roles from "./screens/Roles";
import Organization from "./screens/Organization";
import Layout from "./Layout";
import NewRole from "./screens/NewRole";
import EditRole from "./screens/EditRole";
import Motives from "./screens/Motives";
import OrganizationalUnits from "./screens/OrganizationalUnits";
import NewOrganizationalUnit from "./screens/NewOrganizationalUnit";
import EditOrganizationalUnit from "./screens/EditOrganizationalUnit";
import MedicationsList from "./screens/MedicationsList";
import NursingProtocolsList from "./screens/NursingProtocolsList";
import Vehicles from "./screens/Vehicles";
import RelevantFacts from "./screens/RelevantFacts.";
import DuplicateRole from "./screens/DuplicateRole";
import ParameterizedLists from "./screens/ParameterizedLists";
import Injections from "./screens/Injections";
import CreateOrUpdateInjection from "./screens/CreateOrUpdateInjection";
import Protocols from "./screens/Protocols";
import NewProtocol from "./screens/CreateOrUpdateProtocol";
import VitalSignBounds from "./screens/VitalSignBounds";
import CreateOrUpdateVitalSignBound from "./screens/CreateOrUpdateVitalSignBound";
import { hasLegacyPerm } from "../utils";

export const routes = (
    <Route path="gestionnaire" Component={Layout}
      getData={(data) => {
        if (!hasLegacyPerm(data.context, 'manage:organization')) data.router.replace("/droits-insuffisant");
      }}
    >
        <Redirect from="/" to="/gestionnaire/organisation"/>
        <Route path="organisation" Component={Organization}/>
        <Route path="roles">
            <Route path="/" Component={Roles}/>
            <Route path="new" Component={NewRole}/>
            <Route path=":roleId/dupliquer" Component={DuplicateRole}/>
            <Route path=":roleId/modifier" Component={EditRole}/>
        </Route>
        <Route path="motifs" Component={Motives}/>
        <Route path="unites-organisationnelles">
            <Route path="/" Component={OrganizationalUnits}/>
            <Route path="nouvelle" Component={NewOrganizationalUnit}/>
            <Route path=":id/modifier" Component={EditOrganizationalUnit}/>
        </Route>
        <Route path="protocols">
            <Route path="/" Component={Protocols}/>
            <Route path="edit" Component={NewProtocol}/>
        </Route>
        <Route path="injections">
            <Route path="/" Component={Injections}/>
            <Route path="edit" Component={CreateOrUpdateInjection}/>
        </Route>
        <Route path="bounds">
            <Route path="/" Component={VitalSignBounds}/>
            <Route path="edit" Component={CreateOrUpdateVitalSignBound}/>
        </Route>
        <Route path="medicaments" Component={MedicationsList}/>
        <Route path="pisu" Component={NursingProtocolsList}/>
        <Route path="engins" Component={Vehicles}/>
        <Route path="faits-remarquables" Component={RelevantFacts}/>
        <Route path="listes-parametrees" Component={ParameterizedLists}/>
    </Route>
);
