import React, {useContext} from "react";
import CurrentUserContext from "../CurrentUserContext";
import {SubMenuCntnr, SubMenuItem,} from "../ui/SubMenu";

export default function Layout({children}) {
    const {currentUser} = useContext(CurrentUserContext);

    const hasPerm = requestedPermissionKey => {
        const userPermissions = currentUser.permissions || [];
        return userPermissions.find(p => p.key === requestedPermissionKey) != null;
    };

    return (
        <SubMenuCntnr content={children}>
            <SubMenuItem to="/gestionnaire/organisation" activeClassName="active">
                Organisation
            </SubMenuItem>
            <SubMenuItem to="/gestionnaire/unites-organisationnelles" activeClassName="active">
                Unités organisationnelles
            </SubMenuItem>
            {hasPerm("web:injections-and-protocols:manage") && (
                <>
                    <SubMenuItem to="/gestionnaire/protocols" activeClassName="active">Protocoles</SubMenuItem>
                    <SubMenuItem to="/gestionnaire/injections" activeClassName="active">Injections</SubMenuItem>
                </>
            )}
            {hasPerm("web:bounds:manage") && (
                <SubMenuItem to="/gestionnaire/bounds" activeClassName="active">Bornes</SubMenuItem>
            )}
            {hasPerm("medications:manage") && (
                <SubMenuItem to="/gestionnaire/medicaments" activeClassName="active">
                    Médicaments
                </SubMenuItem>
            )}
            {hasPerm("nursing-protocols:manage") && (
                <SubMenuItem to="/gestionnaire/pisu" activeClassName="active">
                    PISU
                </SubMenuItem>
            )}
            {hasPerm("manage:intervention-motives") && (
                <SubMenuItem to="/gestionnaire/motifs" activeClassName="active">
                    Motifs d'intervention
                </SubMenuItem>
            )}
            <SubMenuItem to="/gestionnaire/roles" activeClassName="active">
                Rôles utilisateurs
            </SubMenuItem>
            {hasPerm("manage:vehicles") && (
                <SubMenuItem to="/gestionnaire/engins" activeClassName="active">
                    Engins
                </SubMenuItem>
            )}
            {hasPerm("manage:relevant-facts") && (
                <SubMenuItem to="/gestionnaire/faits-remarquables" activeClassName="active">
                    Faits remarquables
                </SubMenuItem>
            )}
            {hasPerm("manage:parameterized-lists") && (
                <SubMenuItem to="/gestionnaire/listes-parametrees" activeClassName="active">
                    Listes paramétrées
                </SubMenuItem>
            )}
        </SubMenuCntnr>
    );
}
