import React, { useContext, useState } from 'react';

import styled from 'styled-components';
import PageHeader, { Title } from '../../ui/PageHeader';
import Button from '../../ui/Button';
import Label from '../../ui/Label';
import Input from '../../ui/Input';
import Select from '../../ui/Select';
import theme from '../../ui/theme';
import { useQuery } from '@tanstack/react-query';
import http from '../../http';
import { isAbortError } from '../../utils';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import LoaderBarContext from '../../ui/useLoaderBar';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 3rem;
`;

const InjectionCard = styled.div`
  header {
    height: 40px;
    background-color: #dce8ff;
    width: 100%;
    border-right: unset !important;

    h4 {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 2rem;
    }
  }

  .injection_card_content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    border-right: 2px dashed #dce8ff;
  }
`;

const CustomButtonBar = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 4%;

  button {
    max-height: unset !important;
  }
`;

const PosologyInputs = styled.div`
  display: grid;
  align-items: end;
  grid-template-columns: 30% 65%;
  gap: 5%;
`;

const Table = styled.div`
  padding-left: 3rem;
  border-left: 2px dashed #dce8ff;

  table {
    width: 100%;

    thead {
      margin-bottom: 0.5rem;
    }

    .clear-icon {
      cursor: pointer;
      color: ${theme.red};
    }

    tr {
      border-bottom: 2px solid ${theme.blueBilan};

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export default function CreateOrUpdateProtocol({ match, router }) {
  const controller = new AbortController();
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  const [name, setName] = useState('');

  const [drug, setDrug] = useState(null);
  const [posology, setPosology] = useState(null);
  const [injectionSchema, setInjectionSchema] = useState(null);
  const [injections, setInjections] = useState([]);

  let protocol_id = null;
  if (typeof match.location.query.protocol_id !== 'undefined') {
    protocol_id = match.location.query.protocol_id;
  }

  const { data: protocolSchema } = useQuery(
    ['protocolSchema'],
    async () => {
      return await http
        .get(`protocol_schemas/${match.location.query.protocol_id}.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          setName(res.name);
          setInjections(
            res.injection_schemas.map(it => {
              return { injection_schema: it };
            })
          );
          return res;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération des schémas d'injections");
          throw error;
        });
    },
    { cacheTime: 0, enabled: protocol_id !== null }
  );

  const { data: injectionSchemas } = useQuery(
    ['injectionSchemas'],
    async () => {
      return await http
        .get(`injection_schemas.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.drugs.map(it => {
            return {
              label: it.drug,
              value: it.drug,
              posologies: it.posologies,
            };
          });
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération des schémas d'injections");
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const addInjection = () => {
    injections.push(injectionSchema);
    setInjections([...injections]);
    setDrug(null);
    setPosology(null);
    setInjectionSchema(null);
  };

  const canNotAdd = () => {
    return drug === null || posology === null || injectionSchema === null;
  };

  const create = async () => {
    let url = `protocol_schema.json`;
    if (protocol_id !== null) {
      url = `protocol_schemas/${protocol_id}.json`;
    }
    try {
      await http.post(url, {
        json: {
          name: name,
          injection_schema_ids: injections.map(it => it.injection_schema.id),
        },
      });
      router.push('/gestionnaire/protocols/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(
        "Impossible de créer ou de modifier ce schéma de protocole. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
      );
    } finally {
      setLoaderBar(false);
    }
  };

  const destroy = async () => {
    try {
      await http.delete(`protocol_schemas/${protocol_id}.json`, {
        json: {
          name: name,
          injection_schema_ids: injections.map(it => it.injection_schema.id),
        },
      });
      router.push('/gestionnaire/protocols/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(
        "Impossible de supprimer ce schéma de protocole. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
      );
    } finally {
      setLoaderBar(false);
    }
  };

  const canNotCreate = () => {
    return name === null || name === '' || injections === null || injections.length === 0;
  };

  const clearInjection = idx => {
    injections.splice(idx, 1);
    setInjections([...injections]);
  };

  return (
    <>
      <PageHeader style={{ 'margin-bottom': '1rem' }}>
        {protocol_id !== null ? (
          <Title>Modifier le schéma de protocole : {protocolSchema?.name}</Title>
        ) : (
          <Title>Créer un schéma de protocole</Title>
        )}
      </PageHeader>

      <Content>
        <div>
          <Label htmlFor='unit-name-input'>Nom</Label>
          <Input id='unit-name-input' value={name} onChange={ev => setName(ev.target.value)} />
        </div>

        <InjectionCard>
          <header>
            <h4>Ajouter une injection au protocole</h4>
          </header>
          <div className={'injection_card_content'}>
            <div>
              <Label htmlFor='injection-schema-input'>Sélectionnez un schéma d'injection</Label>
              <Select
                id='injection-schema-input'
                isSearchable={true}
                isClearable={true}
                options={injectionSchemas}
                value={drug}
                onChange={ev => {
                  setDrug(ev);
                  setPosology(null);
                  setInjectionSchema(null);
                }}
                placeholder="Schéma d'injection"
              />
            </div>
            <PosologyInputs>
              <div>
                <Label htmlFor='posology-input'>Sélectionnez une posologie</Label>
                <Select
                  id='posology-input'
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={drug === null}
                  options={drug?.posologies.map(it => {
                    if (it.quantity_maxi) {
                      return {
                        value: `${it.quantity} ${it.unit} (max. ${it.quantity_maxi} ${it.unit_numerator})`,
                        label: `${it.quantity} ${it.unit} (max. ${it.quantity_maxi} ${it.unit_numerator})`,
                        injection_schemas: it.injection_schemas,
                      };
                    } else {
                      return {
                        value: `${it.quantity} ${it.unit}`,
                        label: `${it.quantity} ${it.unit}`,
                        injection_schemas: it.injection_schemas,
                      };
                    }
                  })}
                  value={posology}
                  onChange={ev => {
                    setPosology(ev);
                    setInjectionSchema(null);
                  }}
                  placeholder='Posologie'
                />
              </div>
              <div>
                <Label htmlFor='drug-route-input'>Sélectionnez une voie d'administration</Label>
                <Select
                  id='drug-route-input'
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={posology === null}
                  options={posology?.injection_schemas.map(it => {
                    return {
                      value: it.drug_route_name,
                      label: it.drug_route_name,
                      injection_schema: it,
                    };
                  })}
                  value={injectionSchema}
                  onChange={ev => setInjectionSchema(ev)}
                  placeholder="Voie d'administration"
                />
              </div>
            </PosologyInputs>
            <Button disabled={canNotAdd()} onClick={() => addInjection()}>
              Ajouter
            </Button>
          </div>
        </InjectionCard>

        <Table>
          <table>
            <thead>
              <tr>
                <th>Drogue</th>
                <th>Posology</th>
                <th>Voie d'administration</th>
                <th></th>
              </tr>
            </thead>
            {injections.map((injection, idx) => (
              <tr key={idx}>
                <td>{injection?.injection_schema.drug}</td>
                <td>
                  {injection.injection_schema.quantity_maxi
                    ? `${injection?.injection_schema.quantity} ${injection?.injection_schema.unit} (max. ${injection.injection_schema.quantity_maxi} ${injection?.injection_schema.unit_numerator})`
                    : `${injection?.injection_schema.quantity} ${injection?.injection_schema.unit}`}
                </td>
                <td>{injection?.injection_schema.drug_route_name}</td>
                <td className={'clear-icon'}>
                  <ClearIcon className='warn' onClick={() => clearInjection(idx)} />
                </td>
              </tr>
            ))}
          </table>
        </Table>

        {protocol_id !== null ? (
          <CustomButtonBar style={{ 'margin-top': '2rem' }}>
            <Button className={'warn'} onClick={() => destroy()}>
              Supprimer
            </Button>
            <Button disabled={canNotCreate()} onClick={() => create()}>
              Modifier le schéma de protocole
            </Button>
          </CustomButtonBar>
        ) : (
          <Button disabled={canNotCreate()} onClick={() => create()}>
            Créer le schéma de protocole
          </Button>
        )}
      </Content>
    </>
  );
}
