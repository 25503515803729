import React, { useMemo, useState } from 'react';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { isAbortError } from '../../utils';

import styled from 'styled-components';
import theme from '../../ui/theme';
import PageHeader, { Actions, Title } from '../../ui/PageHeader';
import Button, { LinkButton } from '../../ui/Button';
import LoadingSpinner from '../../ui/LoadingSpinner';
import Card from '../../ui/Card';
import { Link } from 'found';
import Tag from '../../ui/Tag';
import SearchBarCompo, { FilterBar, SearchBar } from '../../ui/SearchBar';
import Input, { InputSearchCntnr, InputSearchIcon } from '../../ui/Input';
import SearchIcon from '@mui/icons-material/Search';

const SchemaInjectionList = styled.ul`
  padding: 0 ${theme.small};
`;

const SchemaInjectionCard = styled(Card)`
  max-width: none;
  min-width: none;

  &:not(:last-child) {
    margin-bottom: ${theme.small};
  }
`;

const SchemaInjectionLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  color: ${theme.text};

  &:hover {
    text-decoration: none;
    color: ${theme.blue};
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const SchemaInjectionCardLabelItem = styled.div`
  margin: ${theme.thin};
  width: 20%;

  @media (max-width: 1200px) {
    width: 48%;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const SchemaInjectionCardContentItem = styled.div`
  margin: ${theme.thin};
  width: 75%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: 48%;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const PosologyRow = styled.div`
  margin: 0.25rem;
  display: grid;
  grid-template-columns: 30% 65%;
  gap: 5%;
`;

const DrugRouteCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function Injections() {
  const controller = new AbortController();
  const [search, setSearch] = useState('');

  const { isLoading: injectionSchemasIsLoading, data: injectionSchemas } = useQuery(
    ['injectionSchemas'],
    async () => {
      return await http
        .get(`injection_schemas.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.drugs;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération des schémas d'injections");
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const clearFilter = () => {
    setSearch('');
  };

  const schemasFiltered = useMemo(() => {
    let temp = [];
    injectionSchemas?.map(s => {
      if (s.drug?.toLowerCase().includes(search.toLocaleLowerCase())) temp.push(s);
    });
    return temp;
  }, [search, injectionSchemas]);

  return (
    <>
      <PageHeader style={{ 'margin-bottom': '1rem' }}>
        <Title>Schémas d'injections</Title>
        <Actions>
          <LinkButton to='/gestionnaire/injections/edit'>Ajouter</LinkButton>
        </Actions>
      </PageHeader>

      <SearchBarCompo hasTag={true} tags={<>{search.length > 0 && <Tag text={search} callback={clearFilter} />}</>}>
        <SearchBar>
          <FilterBar>
            <InputSearchCntnr>
              <InputSearchIcon>
                <SearchIcon />
              </InputSearchIcon>
              <Input type='search' value={search} onChange={ev => setSearch(ev.target.value)} placeholder='Drogue' />
            </InputSearchCntnr>
            <Button className='warn' onClick={clearFilter}>
              Réinitialiser les filtres
            </Button>
          </FilterBar>
        </SearchBar>
      </SearchBarCompo>

      {injectionSchemasIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <>
          <SchemaInjectionList>
            {schemasFiltered?.length > 0 ? (
              <>
                {schemasFiltered.map((schema, idx) => (
                  <SchemaInjectionCard key={idx}>
                    <SchemaInjectionLink
                      to={{
                        pathname: `/gestionnaire/injections/edit`,
                        query: {
                          drug: schema?.drug,
                        },
                      }}
                    >
                      <SchemaInjectionCardLabelItem>{schema?.drug}</SchemaInjectionCardLabelItem>
                      <SchemaInjectionCardContentItem>
                        {schema?.posologies.map((posology, idx) => (
                          <PosologyRow>
                            <span>
                              {posology.quantity_maxi
                                ? `${posology.quantity} ${posology.unit} (max. ${posology.quantity_maxi} ${posology.unit_numerator})`
                                : `${posology.quantity} ${posology.unit}`}
                            </span>
                            <DrugRouteCol>
                              {posology.injection_schemas?.map(injection => (
                                <span>{injection.drug_route_name}</span>
                              ))}
                            </DrugRouteCol>
                          </PosologyRow>
                        ))}
                      </SchemaInjectionCardContentItem>
                    </SchemaInjectionLink>
                  </SchemaInjectionCard>
                ))}
              </>
            ) : (
              <>
                <hr />
                <span>Aucun schéma d'injection</span>
              </>
            )}
          </SchemaInjectionList>
        </>
      )}
    </>
  );
}
