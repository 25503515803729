import React, { useContext, useState } from 'react';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { isAbortError } from '../../utils';
import PageHeader, { Title } from '../../ui/PageHeader';
import Label from '../../ui/Label';
import Select from '../../ui/Select';
import styled from 'styled-components';
import Input, { InputIcon, InputIconCntnr } from '../../ui/Input';
import Button from '../../ui/Button';
import theme from '../../ui/theme';
import ClearIcon from '@mui/icons-material/Clear';
import LoaderBarContext from '../../ui/useLoaderBar';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 3rem;
`;

const PosRouteCard = styled.div`
  header {
    height: 40px;
    background-color: #dce8ff;
    width: 100%;
    border-right: unset !important;

    h4 {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 2rem;
    }
  }

  .pos_route_card_content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    border-right: 2px dashed #dce8ff;
  }
`;

const PosologyInputs = styled.div`
  display: grid;
  align-items: end;
  grid-template-columns: 30% 65%;
  gap: 5%;
`;

const DrugRouteCol = styled.div`
  display: grid;
  grid-template-columns: 70% 25%;
  gap: 5%;

  span,
  div {
    font-size: 0.6rem;
    color: darkgray;
    display: flex;
    justify-content: end;
    align-items: center;
  }
`;

const Table = styled.div`
  padding-left: 3rem;
  border-left: 2px dashed #dce8ff;

  table {
    width: 100%;

    thead {
      margin-bottom: 0.5rem;
    }

    .clear-icon {
      cursor: pointer;
      color: ${theme.red};
    }

    tr {
      border-bottom: 2px solid ${theme.blueBilan};

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export default function CreateOrUpdateInjection({ match, router }) {
  const controller = new AbortController();
  const { loaderBarState, setLoaderBar } = useContext(LoaderBarContext);

  let [posRoutes, setPosRoutes] = useState([]);

  let drugEditName = null;
  if (typeof match.location.query.drug !== 'undefined') {
    drugEditName = match.location.query.drug;
  }

  let [medication, setMedication] = useState(drugEditName);
  const [quantity, setQuantity] = useState(null);
  const [quantityMaxi, setQuantityMaxi] = useState(null);
  const [unit, setUnit] = useState(null);
  const [drugRoute, setDrugRoute] = useState([]);

  const { isLoading: medicationsIsLoading, data: medications } = useQuery(
    ['medicationsList'],
    async () => {
      return await http
        .get(`medications.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.data.map(med => {
            return {
              value: med.name,
              label: med.name,
            };
          });
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des médicaments');
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const { isLoading: drugRoutesIsLoading, data: drugRoutes } = useQuery(
    ['drugRoutesList'],
    async () => {
      return await http
        .get(`drug_routes.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.drug_routes.map(route => {
            return {
              value: route.code,
              label: route.name,
            };
          });
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération des voies d'administrations");
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const addInjection = () => {
    const posRouteExist = posRoutes?.find(
      it => it.quantity === quantity && it.unit === unit?.label && it.quantityMaxi === quantityMaxi
    );
    if (!posRouteExist) {
      posRoutes.push({
        quantity: quantity,
        quantityMaxi: quantityMaxi,
        unit: unit.value,
        unit_numerator: unit.numerator,
        unit_denominator: unit.denominator,
        flowRate: unit.flowRate,
        weightDependant: unit.weightDependant,
        drug_routes: drugRoute.map(it => {
          return {
            value: it.value,
            label: it.label,
            editable: true,
          };
        }),
      });
    } else if (drugRoute.some(it => !posRouteExist.drug_routes.map(it => it.label).includes(it.label))) {
      drugRoute.forEach(it => {
        const drugRouteExist = posRouteExist.drug_routes.find(existing => existing.label === it.label);
        if (!drugRouteExist) {
          posRouteExist.drug_routes.push({
            value: it.value,
            label: it.label,
            editable: true,
          });
        }
      });
    }
    setQuantity('');
    setUnit(null);
    setQuantityMaxi(null);
    setDrugRoute([]);
  };

  const canNotAdd = () => {
    return quantity === null || unit === null || drugRoute === null || drugRoute?.length === 0;
  };

  const { isLoading: unitsIsLoading, data: units } = useQuery(
    ['unitsList'],
    async () => {
      return await http
        .get(`units.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.units.map(unit => {
            return {
              value: unit.name,
              label: unit.name,
              numerator: unit.numerator,
              denominator: unit.denominator,
              flowRate: unit.flow_rate,
              weightDependant: unit.weight_dependant,
            };
          });
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des unités');
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const canNotCreate = () => {
    return medication === null || posRoutes === null || posRoutes?.length === 0;
  };

  const create = async () => {
    let newSchemas = [];
    posRoutes.forEach(posRoute => {
      posRoute.drug_routes.forEach(drugRoute => {
        if (drugRoute.editable) {
          newSchemas.push({
            drug: medication,
            quantity: posRoute.quantity,
            quantity_maxi: posRoute.quantityMaxi,
            unit: posRoute.unit,
            unit_numerator: posRoute.unit_numerator,
            unit_denominator: posRoute.unit_denominator,
            flow_rate: posRoute.flowRate,
            weight_dependant: posRoute.weightDependant,
            drug_route_code: drugRoute.value,
            drug_route_name: drugRoute.label,
          });
        }
      });
    });
    try {
      await http.post(`injection_schema.json`, {
        json: { drug: drugEditName ?? medication, injection_schemas: newSchemas },
      });
      router.push('/gestionnaire/injections/');
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast.error(
        "Impossible de créer ce schéma d'injection. Vérifiez votre connexion internet ou contactez l'assistance Syopé."
      );
    } finally {
      setLoaderBar(false);
    }
  };

  const clearPosRoute = (posRouteIdx, drugRouteIdx) => {
    posRoutes[posRouteIdx].drug_routes.splice(drugRouteIdx, 1);
    if (posRoutes[posRouteIdx].drug_routes.length === 0) {
      posRoutes.splice(posRouteIdx, 1);
    }
    setPosRoutes([...posRoutes]);
  };

  const { data: injectionSchemas } = useQuery(
    ['injectionSchemas'],
    async () => {
      return await http
        .get(`injection_schemas.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          if (drugEditName) {
            setPosRoutes(
              injectionSchemas
                ?.find(it => it.drug === drugEditName)
                ?.posologies?.map(drugPosRoute => {
                  return {
                    quantity: drugPosRoute.quantity,
                    quantityMaxi: drugPosRoute.quantity_maxi,
                    unit: drugPosRoute.unit,
                    unit_numerator: drugPosRoute.unit_numerator,
                    unit_denominator: drugPosRoute.unit_denominator,
                    flow_rate: drugPosRoute.flowRate,
                    weight_dependant: drugPosRoute.weightDependant,
                    drug_routes: drugPosRoute.injection_schemas.map(it => {
                      return {
                        value: it.drug_route_code,
                        label: it.drug_route_name,
                        editable: it.protocol_schema_ids.length === 0,
                      };
                    }),
                  };
                }) ?? []
            );
          }
          return res.drugs;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn("Une erreur est survenue lors de la récupération des schémas d'injections");
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  const onDrugChange = value => {
    setMedication(value);
    setPosRoutes(
      injectionSchemas
        ?.find(it => it.drug === value)
        ?.posologies?.map(drugPosRoute => {
          return {
            quantity: drugPosRoute.quantity,
            unit: drugPosRoute.unit,
            unit_numerator: drugPosRoute.unit_numerator,
            unit_denominator: drugPosRoute.unit_denominator,
            flowRate: drugPosRoute.flowRate,
            weightDependant: drugPosRoute.weightDependant,
            quantityMaxi: drugPosRoute.quantity_maxi,
            drug_routes: drugPosRoute.injection_schemas.map(it => {
              return {
                value: it.drug_route_code,
                label: it.drug_route_name,
                editable: it.protocol_schema_ids.length === 0,
              };
            }),
          };
        }) ?? []
    );
  };

  return (
    <>
      <PageHeader style={{ 'margin-bottom': '1rem' }}>
        {drugEditName ? (
          <Title>Modifier un schéma d'injection</Title>
        ) : (
          <Title>Créer ou modifier un schéma d'injection</Title>
        )}
      </PageHeader>

      <Content>
        {!medicationsIsLoading && medications && (
          <div>
            <Label htmlFor='filter-injection-input'>Sélectionnez une drogue</Label>
            <Select
              id='filter-injection-input'
              isSearchable={true}
              isClearable={true}
              options={medications}
              isDisabled={drugEditName !== null}
              value={medications.find(it => it.value === medication)}
              onChange={ev => onDrugChange(ev.value)}
              placeholder='Sélectionnez une drogue'
            />
          </div>
        )}
        {medication && (
          <>
            <PosRouteCard>
              <header>
                <h4>Posologie / Voie d'administration</h4>
              </header>
              <div className={'pos_route_card_content'}>
                <PosologyInputs>
                  <div>
                    <Label htmlFor='dose-input'>Dose</Label>
                    <Input
                      id='dose-input'
                      type='number'
                      inputMode='numeric'
                      placeholder='Dose'
                      value={quantity}
                      onChange={ev => setQuantity(ev.target.value)}
                    />
                  </div>
                  <div>
                    <Label htmlFor='unit-input'>Sélectionnez une unité</Label>
                    <Select
                      id='unit-input'
                      isSearchable={true}
                      isClearable={true}
                      placeholder='Unité'
                      options={units}
                      value={unit}
                      onChange={ev => setUnit(ev)}
                    />
                  </div>
                  {unit?.weightDependant && (
                    <div>
                      <Label htmlFor='dose-input'>Dose maximum</Label>
                      <InputIconCntnr>
                        <InputIcon className='grey'>{unit?.numerator}</InputIcon>
                        <Input
                          id='dose-input'
                          type='number'
                          inputMode='numeric'
                          placeholder='Dose maximum'
                          value={quantityMaxi}
                          onChange={ev => setQuantityMaxi(ev.target.value)}
                        />
                      </InputIconCntnr>
                    </div>
                  )}
                </PosologyInputs>
                {!drugRoutesIsLoading && drugRoutes && (
                  <div>
                    <Label htmlFor='drug-routes-input'>Sélectionnez une ou plusieurs voies d'administration</Label>
                    <Select
                      id='drug-routes-input'
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      options={drugRoutes}
                      value={drugRoute}
                      onChange={ev => setDrugRoute(ev.map(it => it))}
                      placeholder="Voie(s) d'administration"
                    />
                  </div>
                )}
                <Button disabled={canNotAdd()} onClick={() => addInjection()}>
                  Ajouter
                </Button>
              </div>
            </PosRouteCard>

            <Table>
              <table>
                <thead>
                  <tr>
                    <th>Quantité</th>
                    <th>Unité</th>
                    <th>Quantité maximum</th>
                    <th>Voies d'administration</th>
                  </tr>
                </thead>
                {posRoutes?.map((posRoute, posRouteIdx) => (
                  <tr key={posRouteIdx}>
                    <td>{posRoute?.quantity}</td>
                    <td>{posRoute?.unit}</td>
                    <td>
                      {!posRoute || !posRoute.quantityMaxi
                        ? ''
                        : `${posRoute?.quantityMaxi} ${posRoute?.unit_numerator}`}
                    </td>
                    <td>
                      {posRoute?.drug_routes?.map((drugRoute, drugRouteIdx) => (
                        <DrugRouteCol>
                          {drugRoute.label}
                          {drugRoute.editable ? (
                            <div className={'clear-icon'}>
                              <ClearIcon className='warn' onClick={() => clearPosRoute(posRouteIdx, drugRouteIdx)} />
                            </div>
                          ) : (
                            <span>Affecté à un schéma de protocole</span>
                          )}
                        </DrugRouteCol>
                      ))}
                    </td>
                  </tr>
                ))}
              </table>
            </Table>
          </>
        )}

        <Button style={{ 'margin-top': '2rem' }} disabled={canNotCreate()} onClick={() => create()}>
          {drugEditName ? "Modifier le schéma d'injection" : "Créer le schéma d'injection"}
        </Button>
      </Content>
    </>
  );
}
