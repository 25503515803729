import React, { useContext } from 'react';
import http from '../../http';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { isAbortError } from '../../utils';
import PageHeader, { Actions, Cntnr, Title } from '../../ui/PageHeader';
import Button, { LinkButton } from '../../ui/Button';
import LoadingSpinner from '../../ui/LoadingSpinner';
import styled from 'styled-components';
import theme from '../../ui/theme';

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  border: 1px solid ${theme.grey6};
  border-radius: ${theme.borderRadius};
  padding: ${theme.thin};
  margin-bottom: ${theme.small};

  ul {
    margin-bottom: ${theme.small};
  }
`;

const ItemActions = styled.div`
  margin: 0 -${theme.thin} -${theme.thin};
  background: ${theme.grey6};
  padding: ${theme.small} ${theme.thin};
  text-align: right;
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: ${theme.small};

  thead {
    margin-bottom: 0.5rem;
  }

  tr {
    border-bottom: 2px solid ${theme.blueBilan};

    &:last-child {
      border-bottom: none;
    }
  }
`;

export default function Protocols() {
  const controller = new AbortController();

  const { isLoading: protocolSchemasIsLoading, data: protocolSchemas } = useQuery(
    ['protocolSchemas'],
    async () => {
      return await http
        .get(`protocol_schemas.json`, {
          signal: controller.signal,
        })
        .json()
        .then(res => {
          return res.protocol_schemas;
        })
        .catch(error => {
          if (isAbortError(error)) return;
          console.error(error);
          Sentry.captureException(error);
          toast.warn('Une erreur est survenue lors de la récupération des schémas de protocoles');
          throw error;
        });
    },
    { cacheTime: 0 }
  );

  return (
    <>
      <PageHeader style={{ 'margin-bottom': '1rem' }}>
        <Title>Schémas de protocoles</Title>
        <Actions>
          <LinkButton to='/gestionnaire/protocols/edit'>Ajouter</LinkButton>
        </Actions>
      </PageHeader>

      {protocolSchemasIsLoading ? (
        <LoadingSpinner className='center vh-50' />
      ) : (
        <Cntnr>
          <UnstyledList>
            {protocolSchemas.map((protocol, idx) => (
              <ListItem key={idx}>
                <h2>{protocol.name}</h2>
                <Table style={{ width: '90%', margin: '1rem auto' }}>
                  <thead>
                    <tr>
                      <th>Drogue</th>
                      <th>Posologie</th>
                      <th>Voie d'administration</th>
                    </tr>
                  </thead>
                  {protocol.injection_schemas?.map((injection, idx) => (
                    <tr key={idx}>
                      <td>{injection?.drug}</td>
                      <td>
                        {injection?.quantity_maxi
                          ? `${injection?.quantity} ${injection?.unit} (max. ${injection?.quantity_maxi} ${injection?.unit_numerator})`
                          : `${injection?.quantity} ${injection?.unit}`}
                      </td>
                      <td>{injection?.drug_route_name}</td>
                    </tr>
                  ))}
                </Table>
                <ItemActions>
                  <LinkButton
                    className='filled'
                    to={{
                      pathname: `/gestionnaire/protocols/edit`,
                      query: {
                        protocol_id: protocol?.id,
                      },
                    }}
                  >
                    Modifier
                  </LinkButton>
                </ItemActions>
              </ListItem>
            ))}
          </UnstyledList>
        </Cntnr>
      )}
    </>
  );
}
